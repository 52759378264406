import Img from 'gatsby-image'
import React from 'react'
import styles from './mission.module.css'

export default ({ data }) => (
  <div className={styles.missionBanner}>
    <h2 className={styles.missionTitle}>{data.title}</h2>
    <Img
      className={styles.missionImage}
      alt={data.name}
      fluid={data.missionImage.fluid}
    />
    <div className={styles.missionText} dangerouslySetInnerHTML={ { __html: data.missionText.childMarkdownRemark.html } }></div>
  </div>
)
