import Img from 'gatsby-image'
import React from 'react'
import styles from './intheworks.module.css'

export default ({ works }) => (
  <div className={styles.previewContainer}>
    <div className={styles.backgroundContainer}></div>
    <div className={styles.contentContainer}>
        <Img
            className={styles.worksImage}
            alt="Jackson - train table"
            fluid={works.image.fluid}
        />
        <div className={styles.textArea}>
            <h2 className={styles.headline}>{works.title}</h2>
            <div
                className={styles.details}
                dangerouslySetInnerHTML={{
                    __html: works.longText.childMarkdownRemark.html,
                }}>
            </div>
        </div>
    </div>
  </div>
)
