import ArticlePreview from '../components/article-preview'
import Button from "../components/templates/button"
import favicon from '../images/favicon.ico'
import get from 'lodash/get'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import HeaderSection from '../components/header'
import Impact from '../components/impact'
import Works from '../components/intheworks'
import Layout from '../components/layout'
import Mission from '../components/mission'
import MissionAlt from '../components/mission-alt'
import React from 'react'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulEvent.edges')
    const news = get(this, 'props.data.allContentfulInTheNews.edges')
    const newsCount = get(this, 'props.data.allContentfulInTheNews.totalCount')
    const works = get(this, 'props.data.allContentfulInTheWorks.edges')
    const [banner] = get(this, 'props.data.allContentfulHomePageBanner.edges')
    const missions = get(this, 'props.data.allContentfulMission.edges')
    let missionCount = 0

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} >
            <link rel="icon" href={favicon} />
            <meta name="description" content="The Jackson Walsh Memorial Fund has been established with the mission of enriching the lives of children in and around Burlington, MA" />
            <link rel="canonical" href="https://www.thejacksonwalshmemorialfund.com"></link>
          </Helmet>
          <HeaderSection data={banner.node} />
          <div className="siteContent">
          {missions.map(({ node }) => {
            missionCount++
            if (missionCount % 2 === 1) {
              return (<Mission data={node} />)
            }
            else {
              return (<MissionAlt data={node} />)
            }
          })}
          <div className="impact">
            <div className="impact-headline"><h2>Our Impact</h2></div>
            <div className="in-the-news">
              <h2 className="news-headline">In The News</h2>
              <ul className="news-list">
                {news.map(({ node }) => {
                  return (
                    <li key={node.title}>
                      <Impact news={node} />
                    </li>
                  )
                })}
              </ul>
            </div>
            {newsCount > 2 &&
              <div className="see-more-news">
                <Link style={{ textDecoration: 'none' }} to={`/news`}>See All News
                  <span className="read-more-arrow">
                    arrow_right_alt
                  </span>
                </Link>
              </div>
            }
            {works.length > 0 &&
            <div className="in-the-works">
              <h2 className="works-headline">In The Works</h2>
              <ul className="works-list">
                {works.map(({ node }) => {
                  return (
                    <li key={node.title}>
                      <Works works={node} />
                    </li>
                  )
                })}
              </ul>
            </div>
            }
          </div>
          {posts.length > 1 &&
          <div className="wrapper">
            <h2 className="section-headline">Events</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.briefDescription}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
            <Button className="see-all-button" href="/events">See All Events</Button>
          </div>
          }
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulEvent(sort: { fields: [eventDate], order: DESC }, limit: 3) {
      edges {
        node {
          title
          briefDescription
          eventDate(formatString: "dddd, MMMM Do, YYYY")
          eventImage {
            fluid(maxWidth: 350, maxHeight: 200, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          fullDescription {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulHomePageBanner {
      edges {
        node {
          bannerText
          bannerSubtext
          smallBannerImage {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulMission(sort: { fields: [displayOrder] } ) {
      edges {
        node {
          title
          missionText {
            childMarkdownRemark {
              html
            }
          }
          missionImage {
            fluid(maxWidth: 400, maxHeight: 300, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          multipleImages {
            fluid(maxWidth: 400, maxHeight: 300, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          displayOrder
        }
      }
    }
    allContentfulInTheNews(sort: {fields: createdAt, order: DESC}, limit:2) {
      totalCount
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          link
          newsImage {
            fluid(maxHeight: 300, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulInTheWorks(sort: {fields: createdAt, order: DESC}, filter: {expired: {eq: false}}) {
      edges {
        node {
          title
          longText {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxHeight: 300, quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
